import { default as _91slug_934RjnuxfgfTMeta } from "/var/www/planete-croisiere/preprod/atrium/releases/197/pages/[slug].vue?macro=true";
import { default as aboutLiye7WUhF7Meta } from "/var/www/planete-croisiere/preprod/atrium/releases/197/pages/about.vue?macro=true";
import { default as contactjlkQIbHouZMeta } from "/var/www/planete-croisiere/preprod/atrium/releases/197/pages/contact.vue?macro=true";
import { default as _91slug_93hKrqG4iZPbMeta } from "/var/www/planete-croisiere/preprod/atrium/releases/197/pages/cruiselines/[slug].vue?macro=true";
import { default as indexEljIViVOHgMeta } from "/var/www/planete-croisiere/preprod/atrium/releases/197/pages/cruiselines/index.vue?macro=true";
import { default as _91slug_93i2SSIMyq3PMeta } from "/var/www/planete-croisiere/preprod/atrium/releases/197/pages/cruises/[slug].vue?macro=true";
import { default as _91slug_93yPVRBJgDyGMeta } from "/var/www/planete-croisiere/preprod/atrium/releases/197/pages/departure-port/[slug].vue?macro=true";
import { default as index6TdMnLCjFnMeta } from "/var/www/planete-croisiere/preprod/atrium/releases/197/pages/departure-port/index.vue?macro=true";
import { default as _91slug_93oEsEhGjfdqMeta } from "/var/www/planete-croisiere/preprod/atrium/releases/197/pages/destinations/[slug].vue?macro=true";
import { default as indexPg0wMsPBXsMeta } from "/var/www/planete-croisiere/preprod/atrium/releases/197/pages/destinations/index.vue?macro=true";
import { default as indexqlmp3nBTEYMeta } from "/var/www/planete-croisiere/preprod/atrium/releases/197/pages/faq/index.vue?macro=true";
import { default as indexiomb7bF5O5Meta } from "/var/www/planete-croisiere/preprod/atrium/releases/197/pages/index.vue?macro=true";
import { default as indexrFlan3JcqBMeta } from "/var/www/planete-croisiere/preprod/atrium/releases/197/pages/search/index.vue?macro=true";
import { default as _91slug_93qZeGYJtCurMeta } from "/var/www/planete-croisiere/preprod/atrium/releases/197/pages/ships/[slug].vue?macro=true";
import { default as index5pq7mCaIdZMeta } from "/var/www/planete-croisiere/preprod/atrium/releases/197/pages/ships/index.vue?macro=true";
import { default as sitemapV82qudBnnoMeta } from "/var/www/planete-croisiere/preprod/atrium/releases/197/pages/sitemap.vue?macro=true";
import { default as style_45guidemn6fTmiymHMeta } from "/var/www/planete-croisiere/preprod/atrium/releases/197/pages/style-guide.vue?macro=true";
import { default as test1GVmzlZBXAMeta } from "/var/www/planete-croisiere/preprod/atrium/releases/197/pages/test.vue?macro=true";
import { default as component_45stubBOVlRpu0kMMeta } from "/var/www/planete-croisiere/preprod/atrium/releases/197/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubBOVlRpu0kM } from "/var/www/planete-croisiere/preprod/atrium/releases/197/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___fr",
    path: "/:slug()",
    component: () => import("/var/www/planete-croisiere/preprod/atrium/releases/197/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "about___fr",
    path: "/infos-utiles",
    component: () => import("/var/www/planete-croisiere/preprod/atrium/releases/197/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "contact___fr",
    path: "/contact",
    component: () => import("/var/www/planete-croisiere/preprod/atrium/releases/197/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "cruiselines-slug___fr",
    path: "/compagnie/:slug()",
    component: () => import("/var/www/planete-croisiere/preprod/atrium/releases/197/pages/cruiselines/[slug].vue").then(m => m.default || m)
  },
  {
    name: "cruiselines___fr",
    path: "/compagnie",
    component: () => import("/var/www/planete-croisiere/preprod/atrium/releases/197/pages/cruiselines/index.vue").then(m => m.default || m)
  },
  {
    name: "cruises-slug___fr",
    path: "/croisiere/:slug()",
    component: () => import("/var/www/planete-croisiere/preprod/atrium/releases/197/pages/cruises/[slug].vue").then(m => m.default || m)
  },
  {
    name: "departure-port-slug___fr",
    path: "/ports-de-depart/:slug()",
    component: () => import("/var/www/planete-croisiere/preprod/atrium/releases/197/pages/departure-port/[slug].vue").then(m => m.default || m)
  },
  {
    name: "departure-port___fr",
    path: "/ports-de-depart",
    component: () => import("/var/www/planete-croisiere/preprod/atrium/releases/197/pages/departure-port/index.vue").then(m => m.default || m)
  },
  {
    name: "destinations-slug___fr",
    path: "/destination/:slug()",
    component: () => import("/var/www/planete-croisiere/preprod/atrium/releases/197/pages/destinations/[slug].vue").then(m => m.default || m)
  },
  {
    name: "destinations___fr",
    path: "/destination",
    component: () => import("/var/www/planete-croisiere/preprod/atrium/releases/197/pages/destinations/index.vue").then(m => m.default || m)
  },
  {
    name: "faq___fr",
    path: "/faq",
    component: () => import("/var/www/planete-croisiere/preprod/atrium/releases/197/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/",
    component: () => import("/var/www/planete-croisiere/preprod/atrium/releases/197/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "search___fr",
    path: "/recherche",
    component: () => import("/var/www/planete-croisiere/preprod/atrium/releases/197/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "ships-slug___fr",
    path: "/navire/:slug()",
    component: () => import("/var/www/planete-croisiere/preprod/atrium/releases/197/pages/ships/[slug].vue").then(m => m.default || m)
  },
  {
    name: "ships___fr",
    path: "/navire",
    component: () => import("/var/www/planete-croisiere/preprod/atrium/releases/197/pages/ships/index.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___fr",
    path: "/plan-du-site",
    component: () => import("/var/www/planete-croisiere/preprod/atrium/releases/197/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "style-guide___fr",
    path: "/style-guide",
    meta: style_45guidemn6fTmiymHMeta || {},
    component: () => import("/var/www/planete-croisiere/preprod/atrium/releases/197/pages/style-guide.vue").then(m => m.default || m)
  },
  {
    name: "test___fr",
    path: "/test",
    meta: test1GVmzlZBXAMeta || {},
    component: () => import("/var/www/planete-croisiere/preprod/atrium/releases/197/pages/test.vue").then(m => m.default || m)
  },
  {
    name: component_45stubBOVlRpu0kMMeta?.name,
    path: "/themes/croisieres-en-norvege/pourquoi-choisir-la-croisiere-en-scandinavie",
    component: component_45stubBOVlRpu0kM
  },
  {
    name: component_45stubBOVlRpu0kMMeta?.name,
    path: "/themes/croisieres-de-luxe",
    component: component_45stubBOVlRpu0kM
  },
  {
    name: component_45stubBOVlRpu0kMMeta?.name,
    path: "/themes/croisieres-en-norvege/comment-organiser-au-mieux-sa-croisiere-en-norvege",
    component: component_45stubBOVlRpu0kM
  },
  {
    name: component_45stubBOVlRpu0kMMeta?.name,
    path: "/themes/croisieres-en-norvege/quels-sont-les-avantages-dune-croisiere-en-petit-bateau-dans-les-fjords-norvegiens",
    component: component_45stubBOVlRpu0kM
  },
  {
    name: component_45stubBOVlRpu0kMMeta?.name,
    path: "/navire/costa-magica",
    component: component_45stubBOVlRpu0kM
  },
  {
    name: component_45stubBOVlRpu0kMMeta?.name,
    path: "/navire/croisiere-explora-i",
    component: component_45stubBOVlRpu0kM
  },
  {
    name: component_45stubBOVlRpu0kMMeta?.name,
    path: "/compagnie/croisimer",
    component: component_45stubBOVlRpu0kM
  },
  {
    name: component_45stubBOVlRpu0kMMeta?.name,
    path: "/navire/costa-firenze",
    component: component_45stubBOVlRpu0kM
  },
  {
    name: component_45stubBOVlRpu0kMMeta?.name,
    path: "/navire/costa-luminosa",
    component: component_45stubBOVlRpu0kM
  },
  {
    name: component_45stubBOVlRpu0kMMeta?.name,
    path: "/infos-utiles/abonnement-newsletter",
    component: component_45stubBOVlRpu0kM
  },
  {
    name: component_45stubBOVlRpu0kMMeta?.name,
    path: "/navire/ocean-diamond",
    component: component_45stubBOVlRpu0kM
  },
  {
    name: component_45stubBOVlRpu0kMMeta?.name,
    path: "/navire/msc-europa",
    component: component_45stubBOVlRpu0kM
  },
  {
    name: component_45stubBOVlRpu0kMMeta?.name,
    path: "/navire/ms-lofoten",
    component: component_45stubBOVlRpu0kM
  },
  {
    name: component_45stubBOVlRpu0kMMeta?.name,
    path: "/navire/insigna",
    component: component_45stubBOVlRpu0kM
  },
  {
    name: component_45stubBOVlRpu0kMMeta?.name,
    path: "/croisiere/tour-du-spitzberg-au-royaume-de-l-ours-polaire-1",
    component: component_45stubBOVlRpu0kM
  },
  {
    name: component_45stubBOVlRpu0kMMeta?.name,
    path: "/croisiere/aventure-arctique-7",
    component: component_45stubBOVlRpu0kM
  },
  {
    name: component_45stubBOVlRpu0kMMeta?.name,
    path: "/croisiere/expedition-autour-du-spitzberg-1",
    component: component_45stubBOVlRpu0kM
  },
  {
    name: component_45stubBOVlRpu0kMMeta?.name,
    path: "/croisiere/bergen-kirkenes-bergen-avec-accompagnateur-1",
    component: component_45stubBOVlRpu0kM
  },
  {
    name: component_45stubBOVlRpu0kMMeta?.name,
    path: "/croisiere/aventure-arctique",
    component: component_45stubBOVlRpu0kM
  },
  {
    name: component_45stubBOVlRpu0kMMeta?.name,
    path: "/croisiere/aventure-arctique-6",
    component: component_45stubBOVlRpu0kM
  },
  {
    name: component_45stubBOVlRpu0kMMeta?.name,
    path: "/navire/celebrity-beyond-2",
    component: component_45stubBOVlRpu0kM
  },
  {
    name: component_45stubBOVlRpu0kMMeta?.name,
    path: "/croisiere/costa-fortuna-martinique-guadeloupe-republique-dominicaine-iles-vierges-britanniques-saint-martin-4",
    component: component_45stubBOVlRpu0kM
  },
  {
    name: component_45stubBOVlRpu0kMMeta?.name,
    path: "/croisiere/costa-luminosa-italie-france-espagne-malte-grece-9",
    component: component_45stubBOVlRpu0kM
  },
  {
    name: component_45stubBOVlRpu0kMMeta?.name,
    path: "/croisiere/rois-empereurs",
    component: component_45stubBOVlRpu0kM
  },
  {
    name: component_45stubBOVlRpu0kMMeta?.name,
    path: "/destination/polaire-spitzberg",
    component: component_45stubBOVlRpu0kM
  },
  {
    name: component_45stubBOVlRpu0kMMeta?.name,
    path: "/infos-utiles/l-equipe-planete-croisiere",
    component: component_45stubBOVlRpu0kM
  },
  {
    name: component_45stubBOVlRpu0kMMeta?.name,
    path: "/infos-utiles/bien-choisir-ma-cabine",
    component: component_45stubBOVlRpu0kM
  },
  {
    name: component_45stubBOVlRpu0kMMeta?.name,
    path: "/infos-utiles/questions-frequentes",
    component: component_45stubBOVlRpu0kM
  },
  {
    name: component_45stubBOVlRpu0kMMeta?.name,
    path: "/lp/offre-exclusive-credit-bord-msc",
    component: component_45stubBOVlRpu0kM
  },
  {
    name: component_45stubBOVlRpu0kMMeta?.name,
    path: "/demandes-informations",
    component: component_45stubBOVlRpu0kM
  },
  {
    name: component_45stubBOVlRpu0kMMeta?.name,
    path: "/themes/reprise-croisiere-protocoles-sanitaires",
    component: component_45stubBOVlRpu0kM
  }
]