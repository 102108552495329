import revive_payload_client_4sVQNw7RlN from "/var/www/planete-croisiere/preprod/atrium/releases/197/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/planete-croisiere/preprod/atrium/releases/197/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/planete-croisiere/preprod/atrium/releases/197/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/var/www/planete-croisiere/preprod/atrium/releases/197/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/var/www/planete-croisiere/preprod/atrium/releases/197/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/www/planete-croisiere/preprod/atrium/releases/197/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/planete-croisiere/preprod/atrium/releases/197/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/var/www/planete-croisiere/preprod/atrium/releases/197/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/var/www/planete-croisiere/preprod/atrium/releases/197/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/planete-croisiere/preprod/atrium/releases/197/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_w2VlvAEcpa from "/var/www/planete-croisiere/preprod/atrium/releases/197/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_tbFNToZNim from "/var/www/planete-croisiere/preprod/atrium/releases/197/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import strapi_CICqBBANNd from "/var/www/planete-croisiere/preprod/atrium/releases/197/node_modules/@nuxtjs/strapi/dist/runtime/plugins/strapi.mjs";
import switch_locale_path_ssr_8BKffvaEi5 from "/var/www/planete-croisiere/preprod/atrium/releases/197/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_yfWm7jX06p from "/var/www/planete-croisiere/preprod/atrium/releases/197/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import crisp_chat_o6iCSwMTNw from "/var/www/planete-croisiere/preprod/atrium/releases/197/plugins/crisp-chat.js";
import click_outside_oytHm5ka8v from "/var/www/planete-croisiere/preprod/atrium/releases/197/plugins/click-outside.js";
import google_recaptcha_rYK9Kziv68 from "/var/www/planete-croisiere/preprod/atrium/releases/197/plugins/google-recaptcha.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_w2VlvAEcpa,
  plugin_tbFNToZNim,
  strapi_CICqBBANNd,
  switch_locale_path_ssr_8BKffvaEi5,
  i18n_yfWm7jX06p,
  crisp_chat_o6iCSwMTNw,
  click_outside_oytHm5ka8v,
  google_recaptcha_rYK9Kziv68
]